<template>
  <div>

    <b-embed :src="`${Sucursal.urlReprod}`" scrolling="no">
      <iframe></iframe>
    </b-embed>
    <b-overlay
      :show="isLoading"
      opacity="1"
      no-wrap
      >
      <template #overlay>
        <div class="text-center">
          <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import userservice from '../services/UserServices'
export default {
  data () {
    return {
      isLoading: false

    }
  },
  methods: {
    Logout () {
      userservice.logout().then(res => {
        window.location.href = ''
      })
    }
  },
  computed: {
    Sucursal () {
      
      return {
        nombre: JSON.parse(localStorage.user).Nombre,
        urlReprod: `${process.env.VUE_REPROD_BASE_URL}init/?token=${localStorage.token}`
      }
    }
  },

  mounted () {

  }

}

</script>
<style>
</style>
