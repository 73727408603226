<template>
  <div class="container">
    <b-loading :is-full-page="$isLoadFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    <div class="box">
      <div class="card">
          <b-field label="Nombre del spot" label-position="on-border">
            <b-input v-model="spot.spo_nombre" required></b-input>
          </b-field>
          <b-field label="Tipo de spot" label-position="on-border">
            <b-select v-model="spot.spo_tipo">
              <option v-for="tipo in tipoSpot" :value="tipo.value" :key="tipo.value">
                {{ tipo.texto }}
              </option>
            </b-select>
          </b-field>
          <b-field label="A partir de" label-position="on-border">
            <b-datepicker
             location="es-ES"
             placeholder="Ingresar fecha "
             icon="calendar-today"
             trap-focus
             v-model="spot.spo_fecini" required></b-datepicker>
          </b-field>
          <b-field label="Finaliza en" label-position="on-border">
            <b-datepicker
              location="es-ES"
              placeholder="Ingresar fecha "
              icon="calendar-today"
              trap-focus
              v-model="spot.spo_fecfin"
              :min="spot.spo_fecini"
              required
            ></b-datepicker>
          </b-field>

      </div>
      <div class="card">
        <b-tabs>
          <b-tab-item label="Audio propio">
            <b-field>
              <b-upload
                v-model="file"
                accept="audio/*"
                >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir audio</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
            </b-upload>
            </b-field>
          </b-tab-item>
          <b-tab-item label="Generar con IA">

              <b-field grouped group-multiline class="is-grouped-multiline">
                <b-field label="Loacutor virtual" label-position="on-border">
                  <b-select placeholder="Select a character" icon="account" v-model="selectedLoc">
                    <option
                      v-for="locutor in locutores"
                      :key="locutor.voice_id"
                      :value="locutor"
                    >
                      <strong class="tag">{{ locutor.name }}</strong>
                    </option>
                  </b-select>

              </b-field>
                <b-field label="Texto a convertir en audio por IA" label-position="on-border" >
                  <b-input v-model="MensajeSpot" :maxlength="CarDispVoz" ></b-input>
                </b-field>

                  <p class="control">
                    <b-button @click="generarVoz()" class="is-success">Generar audio</b-button>
                  </p>
              </b-field>
          </b-tab-item>
        </b-tabs>
        <br>
        <br>
        <b-field label="Audio cargado" label-position="on-border">
            <audio ref="audiofile" controls :src="srcAudio" style="width: 80%; height: 50px;"></audio>
          </b-field>
      </div>
      <br>
      <div class="buttons">
        <b-button @click="guardar" class="is-success">Guardar</b-button>
        <b-button @click="btnCancelar" class="is-danger">Cancelar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../components/NotificationPlugin/NotificationTemplate'
import spotService from '../../services/SpotServices'
import moment from 'moment'
import handleError from '../../utils/handleErrors'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import ServicesElevenLabs from '../../services/RequestElevenLabsServices'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    ScaleLoader
  },

  data () {
    const i18labels = this.$t('modulo_spots.biblioteca_spots.alta_spot')
    return {
      isLoading: false,
      spot: {
        spo_codigo: -1,
	      spo_nombre: null,
      	spo_dursec: 0,
	      spo_source: null,
	      spo_fecini: null,
	      spo_fecfin: null,
	      spo_codigoColor: null,
	      spo_tipo: null,
	      spo_usuario: null
      },
      file: null,
      srcAudio: '',
      srcAudioLabel: '',
      textos: {
        title: i18labels.titulo,
        alertOk: i18labels.mensajeAlertaOk,
        mensajeAlertaEditOk: i18labels.mensajeAlertaEditOk
      },
      idSpot: this.$route.params.idSpot,
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      idHistory: '',
      selectedLoc: null,
      locutores: [],
      tipoSpot: [
        { texto: 'Institucional', value: 'inst' },
        { texto: 'Noticias', value: 'noti' },
        { texto: 'Promocional', value: 'prom' }
      ],
      fechaInicio: '',
      fechaFin: '',
      MensajeSpot: '',
      CarDispVoz: 0,
      isEdit: false

    }
  },
  methods: {
    guardar () {
      this.isLoading = true
      this.submitAudioFile()
    },
    loadAudio () {
      const reader = new FileReader()
      const that = this
      reader.onload = function (e) {
        that.srcAudio = e.target.result
        that.srcAudioLabel = 'Audio personal cargado'
      }
      reader.readAsDataURL(this.file)
    },
    generarVoz () {
      this.isLoading = true
      ServicesElevenLabs
        .textToSpeech(this.selectedLoc.voice_id, this.MensajeSpot)
        .then((audio) => {
          this.file = audio[1]
          this.srcAudio = audio[0]
          this.srcAudioLabel = `Audio generado con locutor inteligente (${this.selectedLoc.name})`
          ServicesElevenLabs.getInfoUser().then((data) => {
            this.CarDispVoz = data.character_limit - data.character_count
          })
        })
      // .then(() => {
      //   ServicesElevenLabs.getHistoryCreate(true).then(() => {
      //     this.idHistory = ServicesElevenLabs.GetHistory(this.selectedLoc.voice_id, this.MensajeSpot).id_history
      //     this.isLoading = false
      //   }).then(() => {

      //   })
      // })
    },
    submitAudioFile () {
      const audio = this.$refs.audiofile
      const formdata = new FormData()
      if (this.file != null) {
        formdata.append('file', this.file, 'audio.mp3')
        formdata.append('spo_dursec', parseInt(audio.duration))
      }
      for (const key in this.spot) {
        if (Object.hasOwnProperty.call(this.spot, key)) {
          let value = this.spot[key]
          if (key == 'spo_fecini' || key == 'spo_fecfin') {
            value = moment(value).format('YYYY-MM-DD')
          }
          formdata.append(key, value)
        }
      }
      const action = (this.spot.spo_codigo != -1) ? spotService.editarSpot(formdata) : spotService.guardarSpot(formdata)
      // el res contiene la última parte del path donde se guardó el spot
      action.then(response => {
        // Si da todo ok, muestro mensaje success
        this.isLoading = false
        this.$buefy.toast.open('Registro exitoso')
        this.btnCancelar()
      }).catch(err => {
        this.isLoading = false
        this.$buefy.toast.open(err)
      })
    },
    btnCancelar () {
      this.$router.push({
        name: 'Mis spots'
      })
    },
    editarSpot () {
      this.isLoading = true
      
      const fechaDesde = this.fechaInicio
      const fechaHasta = (this.spot.spo_tipo === 'inst') ? null : this.fechaFin

      spotService.editarSpot(this.spot).then(res => {
        this.notificacion.mensaje = this.textos.mensajeAlertaEditOk
        this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)
        this.redirectTo('bibliotecaSpot')
        this.isLoading = false
      })
        .catch(err => {
          this.notificacion.mensaje = handleError.errorApi(err)
          console.log(this.notificacion.mensaje)
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
          this.isLoading = false
        })
    },
    redirectTo (pathComponent) {
      this.$router.push({ path: pathComponent })
    },
    notifyVue (verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 8000
      })
    },
    verificarParametros () {
      this.isLoading = true
      if (this.$route.params.spo_codigo != undefined) {
        this.isEdit = true
        this.spot.spo_codigo = this.$route.params.spo_codigo
        this.spot.spo_nombre = this.$route.params.spo_nombre
        this.spot.spo_source = this.$route.params.spo_source
        const fini = new Date(this.$route.params.spo_fecini.split('-').map(d => parseInt(d)).reverse())
        const ffin = new Date(this.$route.params.spo_fecfin.split('-').map(d => parseInt(d)).reverse())
        this.spot.spo_fecini = fini
        this.spot.spo_fecfin = ffin
        
        this.srcAudio = this.$route.params.spo_source_src
        this.srcAudioLabel = 'Cargue un nuevo audio para reemplazar'
        this.spot.spo_tipo = this.$route.params.spo_tipo
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.i18n = this.$i18n
    this.isLoading = true
    ServicesElevenLabs.getVoicesCloned().then((locutores) => {
      this.locutores = locutores
      this.verificarParametros()
      ServicesElevenLabs.getInfoUser().then((data) => {
        this.CarDispVoz = data.character_limit - data.character_count
      })
    })
  },
  watch: {
    file (newval) {
      this.loadAudio()
    },
    'spot.spo_tipo' (newVal) {
      if (this.spot.spo_codigo !== -1) return
      switch (newVal) {
        case 'inst':
          this.spot.spo_fecini = new Date()
          this.spot.spo_fecfin = new Date(9999, 1, 1)
          break
        case 'noti':
          this.spot.spo_fecini = new Date()
          this.spot.spo_fecfin = new Date()
          break
        case 'prom':
          this.spot.spo_fecini = null
          this.spot.spo_fecfin = null
          break
      }
    }
  }
}

</script>
<style>

</style>
